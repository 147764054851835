import React, { useState } from 'react';
import '../index.css';

const Header = ({ toggleLanguage, currentLanguage }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <header className="header">
      <div className="logo"></div>
      <div className="fullscreennav">
        <ul className="icon-nav">
          <li><a rel="noopener noreferrer" target="_blank" href="https://github.com/fiftysixcrypto"><i className="fab fa-github"></i></a></li>
          <li><a rel="noopener noreferrer" target="_blank" href="https://discord.com/invite/XuhW2ykW3D"><i className="fab fa-discord"></i></a></li>
          <li><a rel="noopener noreferrer" target="_blank" href="https://twitter.com/fiftysixcrypto"><i className="fa-brands fa-x-twitter"></i></a></li>
          <li><a rel="noopener noreferrer" target="_blank" href="mailto:business@fiftysix.xyz"><i className="far fa-envelope"></i></a></li>
        </ul>
      </div>
      <button className="menu-button" onClick={toggleCollapsed}>☰</button>
      <nav className={`nav ${isCollapsed ? 'collapsed-header' : ''}`}>
        <ul className="main-nav">
          <li><a className="white small-text" href="/">Home</a></li>
          <li><a className="white small-text" href="#/nodevin">Nodevin</a></li>
          <li><a className="white small-text" href="#/resources">Resources</a></li>
          <li><a className="white small-text" href="#/manifesto">Manifesto</a></li>
          <li><a className="white small-text" href="#/about-us">About</a></li>
          <li><a className="white small-text" href="#/contact">Contact</a></li>
        </ul>
        <ul className="icon-nav">
          <li><a rel="noopener noreferrer" target="_blank" href="https://github.com/fiftysixcrypto"><i className="fab fa-github"></i></a></li>
          <li><a rel="noopener noreferrer" target="_blank" href="https://discord.com/invite/XuhW2ykW3D"><i className="fab fa-discord"></i></a></li>
          <li><a rel="noopener noreferrer" target="_blank" href="https://twitter.com/fiftysixcrypto"><i className="fa-brands fa-x-twitter"></i></a></li>
          <li><a rel="noopener noreferrer" target="_blank" href="mailto:business@fiftysix.xyz"><i className="far fa-envelope"></i></a></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
