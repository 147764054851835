import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Route, Routes, HashRouter } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ContactPage from './pages/ContactPage';
import ManifestoPage from './pages/ManifestoPage';
import ResourcesPage from './pages/ResourcesPage';
import NodevinPage from './pages/NodevinPage';
import AboutUsPage from './pages/AboutUsPage';

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <Routes>
        <Route path="/about-us" element={<AboutUsPage/>} />
        <Route path="/nodevin" element={<NodevinPage/>} />
        <Route path="/resources" element={<ResourcesPage/>} />
        <Route path="/contact" element={<ContactPage/>} />
        <Route path="/manifesto" element={<ManifestoPage/>} />
        <Route path="/" element={<HomePage/>} />
      </Routes>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
