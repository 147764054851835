import React, { useState } from 'react';
import Header from '../components/Header'
import '../resources.css'

const ResourcesPage = () => {
  const [language, setLanguage] = useState('en');

  const toggleLanguage = () => {
    setLanguage(prevLanguage => prevLanguage === 'en' ? 'ru' : 'en');
  };

  return (
    <div className="App">
      <Header toggleLanguage={toggleLanguage} currentLanguage={language}/>

      <div className="">
        <div className="sidebar">
          <div className="options">
            <div className="sidebar-option">
              <a className="white" href="/">Home</a>
            </div>
            <div className="sidebar-option sidebar-margin">
              <a className="white" href="#/nodevin">Nodevin</a>
            </div>
            <div className="sidebar-option sidebar-margin">
              <a className="selected" href="#/resources"><u>{">"} Resources</u></a>
            </div>
            <div className="sidebar-option sidebar-margin">
              <a className="white" href="#/manifesto">Manifesto</a>
            </div>
            <div className="sidebar-option sidebar-margin">
              <a className="white" href="#/about-us">About</a>
            </div>
            <div className="sidebar-option sidebar-margin">
              <a className="white" href="#/contact">Contact</a>
            </div>
          </div>
        </div>

        <div className="resources-page-wrapper">
          <img src="images/fiftysix-logo.png" alt="Fiftysix Logo" className="centered-image"/>
          <h1 className="title">Resources</h1>
          <div className="sub-text-wrapper">
            <p className="sub-text">
              A collection of free resources offered by Fiftysix.
            </p>
          </div>
          <br/>
          <div className="resources-page-wrapper resources-text">
            <h2 className="white">For Developers:</h2>
            <p className="white specific-resource-text">
              <a rel="noopener noreferrer" target="_blank" className="selected" href="https://github.com/fiftysixcrypto">
                <strong><u>Fiftysix Github</u></strong>
              </a>: Access to our latest open-source projects, code, and documentation. Join our community of developers, contribute to ongoing projects, and stay updated with the newest releases and innovations.
            </p>
            <p className="white specific-resource-text">
              <a rel="noopener noreferrer" target="_blank" className="selected" href="https://hub.docker.com/u/fiftysix">
                <strong><u>Fiftysix Dockerhub</u></strong>
              </a>: Easily deploy and manage blockchain software in your containerized environments. Access pre-built images, pull the latest versions, and more.
            </p>
            <p className="white specific-resource-text">
              <a rel="noopener noreferrer" target="_blank" className="selected" href="https://github.com/fiftysixcrypto/Ethereum-Developer-Resources">
                <strong><u>Ethereum Developer Resources</u></strong>
              </a>: A curated list of smart contracts, tutorials, guides, and more for both beginner and advanced developers interested in building on Ethereum (and EVM compatible blockchains).
            </p>

            <br/>
            
            <h2 className="white">Blockchain Insights:</h2>
            <p className="white specific-resource-text">
              <a rel="noopener noreferrer" target="_blank" className="selected" href="Blockchain-Technology-Behind-the-Future.pdf">
                <strong><u>Blockchain: Technology Behind the Future</u></strong>
              </a>: Insight into blockchain technology highlighting its security, immutability, transparency, and applications across various industries. {" "}
              <a a rel="noopener noreferrer" target="_blank" className="selected" href="RU-Blockchain-Technology-Behind-the-Future.pdf">русский</a>
            </p>
            <p className="white specific-resource-text">
              <a rel="noopener noreferrer" target="_blank" className="selected" href="Bitcoin-A-Digital-Store-of-Value.pdf">
                <strong><u>Bitcoin: A Digital Store of Value</u></strong>
              </a>: Exploration of Bitcoin's origin, significance, and market dynamics, emphasizing its role as a decentralized digital currency. {" "}
              <a a rel="noopener noreferrer" target="_blank" className="selected" href="RU-Bitcoin-A-Digital-Store-of-Value.pdf">русский</a>
            </p>
            <p className="white specific-resource-text">
              <a rel="noopener noreferrer" target="_blank" className="selected" href="Ethereum-A-Digital-Currency-for-Smart-Contracts.pdf">
                <strong><u>Ethereum: A Digital Currency for Smart Contracts</u></strong>
              </a>: Overview of Ethereum, focusing on smart contracts, decentralized finance applications, and factors influencing its adoption. {" "}
              <a a rel="noopener noreferrer" target="_blank" className="selected" href="RU-Ethereum-A-Digital-Currency-for-Smart-Contracts.pdf">русский</a>
            </p>

            <br/>

            <p className="white" style={{textAlign: "center"}}>
              —
            </p>
            <br/>
            <div className="link-container">
              <a href="#/manifesto" className="">
                Manifesto
              </a>
              <a href="#/contact" className="">
                Contact
              </a>
              <a href="/" className="">
                Home
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResourcesPage;
