import React, { useState } from 'react';
import Header from '../components/Header'
import '../resources.css'

const NodevinPage = () => {
  const [language, setLanguage] = useState('en');

  const toggleLanguage = () => {
    setLanguage(prevLanguage => prevLanguage === 'en' ? 'ru' : 'en');
  };

  return (
    <div className="App">
      <Header toggleLanguage={toggleLanguage} currentLanguage={language}/>

      <div className="">
        <div className="sidebar">
          <div className="options">
            <div className="sidebar-option">
              <a className="white" href="/">Home</a>
            </div>
            <div className="sidebar-option sidebar-margin">
              <a className="selected" href="#/nodevin"><u>{">"} Nodevin</u></a>
            </div>
            <div className="sidebar-option sidebar-margin">
              <a className="white" href="#/resources">Resources</a>
            </div>
            <div className="sidebar-option sidebar-margin">
              <a className="white" href="#/manifesto">Manifesto</a>
            </div>
            <div className="sidebar-option sidebar-margin">
              <a className="white" href="#/about-us">About</a>
            </div>
            <div className="sidebar-option sidebar-margin">
              <a className="white" href="#/contact">Contact</a>
            </div>
          </div>
        </div>

        <div className="manifesto-page-wrapper">
          <img src="images/fiftysix-logo.png" alt="Fiftysix Logo" className="centered-image"/>
          <h1 className="title">Nodevin</h1>
          <div className="sub-text-wrapper">
            <p className="sub-text">
              Run any blockchain node, anywhere.
            </p>
          </div>
          <br/>

          <div className="resources-page-wrapper">
            <div className="accordion-item">
              <div className="accordion">
                <h2 className="white">What is Nodevin?</h2>
                <p className="nodevin-answer">
                  Nodevin is open source, command-line software designed to simplify the process of running and managing blockchain nodes. Whether you're a developer, institution, or enthusiast, Nodevin provides an easy and efficient way to participate in blockchain decentralization.
                </p>
              </div>
            </div>

            <div className="accordion-item">
              <div className="accordion">
                <h2 className="white">Why Nodevin?</h2>
                <p className="nodevin-answer">
                  Setting up and managing blockchain nodes can be technically challenging due to the complexities involved in configuring, maintaining, and updating software. Nodevin removes these barriers by providing an intuitive tool that automates these tasks. With a single command, Nodevin can deploy a fully functional blockchain node, handling everything from setup to synchronization.
                </p>
                <p className="nodevin-answer">
                  The Nodevin daemon continuously monitors and updates nodes, ensuring they are always running the latest software versions, reducing maintenance and downtime. By simplifying node management, Nodevin empowers a broader audience to participate in and enhance the decentralization of blockchain networks.
                </p>
              </div>
            </div>

            <div className="accordion-item">
              <div className="accordion">
                <h2 className="white">Key Features of Nodevin:</h2>
                <ul className="nodevin-answer">
                  <li className="nodevin-answer"><strong>Easy Setup:</strong> Start blockchain nodes with a single command.</li>
                  <li className="nodevin-answer"><strong>Automatic Updates:</strong> Ensure nodes are always running their latest software versions.</li>
                  <li className="nodevin-answer"><strong>Infinitely Customizable:</strong> Specify node ports, storage path, startup command, and more.</li>
                  <li className="nodevin-answer"><strong>Cross-Platform Support:</strong> Compatible with Linux, macOS, and Windows.</li>
                </ul>
                <p className="nodevin-answer">Nodevin currently supports <a rel="noopener noreferrer" target="_blank" className="white" href="https://github.com/fiftysixcrypto/nodevin/blob/main/SUPPORTED-NETWORKS.md">the following blockchains</a>.</p>
              </div>
            </div>

            <div className="accordion-item">
              <div className="accordion">
                <h2 className="white">Benefits of Nodevin:</h2>
                <ul className="nodevin-answer">
                  <li className="nodevin-answer"><strong>Network Decentralization:</strong> <span className="nodevin-answer">Run blockchain nodes on your terms, supporting network stability.</span></li>
                  <li className="nodevin-answer"><strong>User Friendly:</strong> <span className="nodevin-answer">No technical knowledge required to set up and manage blockchain nodes.</span></li>
                  <li className="nodevin-answer"><strong>Community Support:</strong> <span className="nodevin-answer">Nodevin is open source, built and maintained by a community of contributors.</span></li>
                </ul>
              </div>
            </div>

            <div className="accordion-item">
              <div className="accordion">
                <h2 className="white">Links:</h2>
                <ul className="nodevin-answer">
                  <li className="selected"><a rel="noopener noreferrer" target="_blank" className="selected" href="https://nodevin.xyz"><strong>Website</strong></a></li>
                  <li className="selected"><a rel="noopener noreferrer" target="_blank" className="selected" href="https://github.com/fiftysixcrypto/nodevin"><strong>Github</strong></a></li>
                  <li className="selected"><a rel="noopener noreferrer" target="_blank" className="selected" href="https://github.com/fiftysixcrypto/nodevin/releases"><strong>Download</strong></a></li>
                  <li className="selected"><a rel="noopener noreferrer" target="_blank" className="selected" href="mailto:nodes@fiftysix.xyz"><strong>Contact</strong></a></li>
                </ul>
              </div>
            </div>

            <br/>
            <p className="white" style={{textAlign: "center"}}>
              —
            </p>
            <br/>

            <div className="link-container">
              <a href="#/resources" className="">
                Resources
              </a>
              <a href="#/manifesto" className="">
                Manifesto
              </a>
              <a href="/" className="">
                Home
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NodevinPage;
