import React, { useState } from 'react';
import Header from '../components/Header'
import '../index.css';

const HomePage = () => {
  const [language, setLanguage] = useState('en');

  const toggleLanguage = () => {
    setLanguage(prevLanguage => prevLanguage === 'en' ? 'ru' : 'en');
  };

  return (
    <div className="App">
      <Header toggleLanguage={toggleLanguage} currentLanguage={language}/>

      <div>
        <div className="sidebar">
          <div className="options">
            <div className="sidebar-option">
              <a className="selected" href="/"><u>{">"} Home</u></a>
            </div>
            <div className="sidebar-option sidebar-margin">
              <a className="white" href="#/nodevin">Nodevin</a>
            </div>
            <div className="sidebar-option sidebar-margin">
              <a className="white" href="#/resources">Resources</a>
            </div>
            <div className="sidebar-option sidebar-margin">
              <a className="white" href="#/manifesto">Manifesto</a>
            </div>
            <div className="sidebar-option sidebar-margin">
              <a className="white" href="#/about-us">About</a>
            </div>
            <div className="sidebar-option sidebar-margin">
              <a className="white" href="#/contact">Contact</a>
            </div>
          </div>
        </div>
        <div className="content">
          <img src="images/fiftysix-logo.png" alt="Fiftysix Logo" className="centered-image"/>
          <h1 className="title">Pushing blockchain decentralization forward.</h1>
          <div className="sub-text-wrapper">
            <p className="sub-text">
              Stand up any blockchain node with a single command. Free.
            </p>
          </div>
          <div className="wrapper">
            <div className="container">
              <a className="search-submit button" href="#/nodevin">
                {
                  window.innerWidth > 400
                    ? <strong>Learn More About Nodevin</strong>
                    : <strong>Learn More</strong>
                }
              </a>
            </div>
          </div>
          <div className="we-support-section">
            <div>
              <div className="bump"></div>
              <div className="wrapper">
                <div className="support-container">
                  <div className="logo-container">
                    <div className="logo-fest">
                      <img src="images/bitcoin-logo.png" alt="Bitcoin"/>
                    </div>
                    <div className="logo-fest">
                      <img src="images/ethereum-logo.png" alt="Ethereum"/>
                    </div>
                    <div className="logo-fest">
                      <img src="images/litecoin-logo.png" alt="Litecoin"/>
                    </div>
                    <div className="logo-fest">
                      <img src="images/dogecoin-logo.png" alt="Dogecoin"/>
                    </div>
                    <div className="logo-fest">
                      <img src="images/ethereum-classic-logo.png" alt="Eth-Classic"/>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <a href="#/contact" className="general-link">
                  Want to add your chain? Reach out here.
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
