import React, { useState } from 'react';
import Header from '../components/Header'
import '../index.css'

const ContactPage = () => {
  const [language, setLanguage] = useState('en');

  const toggleLanguage = () => {
    setLanguage(prevLanguage => prevLanguage === 'en' ? 'ru' : 'en');
  };

  return (
    <div className="App">
      <Header toggleLanguage={toggleLanguage} currentLanguage={language}/>

      <div className="">
        <div className="sidebar">
          <div className="options">
            <div className="sidebar-option">
              <a className="white" href="/">Home</a>
            </div>
            <div className="sidebar-option sidebar-margin">
              <a className="white" href="#/nodevin">Nodevin</a>
            </div>
            <div className="sidebar-option sidebar-margin">
              <a className="white" href="#/resources">Resources</a>
            </div>
            <div className="sidebar-option sidebar-margin">
              <a className="white" href="#/manifesto">Manifesto</a>
            </div>
            <div className="sidebar-option sidebar-margin">
              <a className="white" href="#/about-us">About</a>
            </div>
            <div className="sidebar-option sidebar-margin">
              <a className="selected" href="#/contact"><u>{">"} Contact</u></a>
            </div>
          </div>
        </div>

        <div className="manifesto-page-wrapper">
          <img src="images/fiftysix-logo.png" alt="Fiftysix Logo" className="centered-image"/>
          <h1 className="title">Contact Us</h1>
          <div className="sub-text-wrapper">
            <p className="sub-text">
              The best way to get in touch with us.
            </p>
          </div>
          <br/>
          <div className="sub-text-wrapper">
            <p className="sub-text white">
              <u>Email</u>: business@fiftysix.xyz
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
