import React, { useState } from 'react';
import Header from '../components/Header'
import '../manifesto.css'

const ManifestoPage = () => {
  const [language, setLanguage] = useState('en');

  const toggleLanguage = () => {
    setLanguage(prevLanguage => prevLanguage === 'en' ? 'ru' : 'en');
  };

  return (
    <div className="App">
      <Header toggleLanguage={toggleLanguage} currentLanguage={language}/>

      <div className="">
        <div className="sidebar">
          <div className="options">
            <div className="sidebar-option">
              <a className="white" href="/">Home</a>
            </div>
            <div className="sidebar-option sidebar-margin">
              <a className="white" href="#/nodevin">Nodevin</a>
            </div>
            <div className="sidebar-option sidebar-margin">
              <a className="white" href="#/resources">Resources</a>
            </div>
            <div className="sidebar-option sidebar-margin">
              <a className="selected" href="#/manifesto"><u>{">"} Manifesto</u></a>
            </div>
            <div className="sidebar-option sidebar-margin">
              <a className="white" href="#/about-us">About</a>
            </div>
            <div className="sidebar-option sidebar-margin">
              <a className="white" href="#/contact">Contact</a>
            </div>
          </div>
        </div>

        <div className="manifesto-page-wrapper">
          <img src="images/fiftysix-logo.png" alt="Fiftysix Logo" className="centered-image"/>
          <h1 className="title">The Fiftysix Manifesto</h1>
          <div className="sub-text-wrapper">
            <p className="sub-text">
              Make blockchain accessible, efficient, and reliable.
            </p>
          </div>
          <br/>
          <div className="manifesto-wrapper manifesto-text">
            <p className="white">
              —
            </p>
            <p className="white">
              "What is needed is an electronic payment system based on cryptographic proof instead of trust,
              allowing any two willing parties to transact directly with each other without the need for a trusted third party”
              - <strong>Satoshi Nakamoto</strong>, <a rel="noopener noreferrer" className="white" target="_blank" href="https://bitcoin.org/bitcoin.pdf">Bitcoin Whitepaper</a>
            </p>
            <p className="white">
              —
            </p>
            <br/>
            <p className="manifesto-text">
              We say blockchains are decentralized, but we don't really mean it.
            </p>
            <p className="manifesto-text">
              Blockchains are becoming increasingly challenging to run and harder to afford.
            </p>
            <p className="manifesto-text">
              The "trusted third party" is now industrial.
            </p>
            <p className="manifesto-text">
              Today's "electronic payment systems" are focused on speed and cost rather than trust and decentralization.
            </p>
            <br/>
            <p className="manifesto-text selected">
              <strong>If we aren't careful, we will revert to old currency standards.</strong>
            </p>
            <br/>
            <p className="white">
              —
            </p>
            <br/>
            <p className="manifesto-text">
              If you're reading this, you're probably a cryptocurrency enthusiast. You might even own some Bitcoin.
            </p>
            <p className="manifesto-text">
              But, do you run any blockchain nodes?
            </p>
            <p className="manifesto-text">
              There are over 10,000 blockchains in existence today. Only a handful are properly decentralized.
            </p>
            <p className="manifesto-text">
              We talk about settlement, zero-knowledge proofs, sub-cent transaction fees... and little about decentralization.
            </p>
            <p className="manifesto-text">
              The real reason Satoshi wrote Bitcoin. Creating a trusted third party.
            </p>
            <br/>
            <p className="manifesto-text selected">
              <strong>
                Blockchain innovation is irrelevant without decentralization.
              </strong>
            </p>
            <br/>
            <p className="white">
              —
            </p>
            <br/>
            <p className="manifesto-text">
              Someone needs to make blockchain accessible, efficient, and reliable.
            </p>
            <p className="manifesto-text">
              That's where we step in.
            </p>
            <p className="manifesto-text">
              Fiftysix has extensive experience building, running, and serving blockchain nodes.
            </p>
            <p className="manifesto-text">
              We'll help you do it too.
            </p>
            <p className="manifesto-text">
              Let's become the trusted third party, together.
            </p>
            <br/>
            <p className="manifesto-text selected">
              <strong>
                We're Fiftysix, and we believe in decentralization.
              </strong>
            </p>
            <br/>
            <p className="white">
              —
            </p>
            <br/>
            <div className="link-container">
              <a href="#/nodevin" className="">
                Nodevin
              </a>
              <a href="#/contact" className="">
                Contact
              </a>
              <a href="/" className="">
                Home
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManifestoPage;
